.container {
  /*width: 1170px;*/
}
.navbar {
  /*min-width: 1170px;*/
  position: static;
  height: 64px;
  background-color: #27ae60;
  border: none;
}

.navbar-header {
  display: inline-block;
}
.navbar-default .navbar-brand {
  color: #ffffff;
}
.navbar-default .navbar-brand:hover {
  color: #ffffff;
}
.navbar-right {
  display: inline-block;
  padding-top: 0;
  padding-bottom: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  float: right;
}
.navbar-right .form-group {
  display: inline-block;
  margin-bottom: 0;
}

.auth-form-email {
  margin-right: 15px;
  color: #ffffff;
}
.amcharts-chart-div a:last-child {
  display: none !important;
}

.clark-error-chart-panel {
  width: 265px;
  display: inline-block;
  margin-right: 25px;
}
.clark-error-chart-panel .panel-body {
  padding: 0;
}
.clark-error-chart-panel .panel-footer {
  font-weight: bold;
  text-align: center;
  color: #555;
}
.clark-error-chart-panel .panel-footer table td:first-child {
  text-align: left;
}
.clark-error-chart-panel .panel-footer table td:last-child {
  text-align: right;
}

.clark-error-chart-panel:nth-child(3n) {
  margin-right: 0;
}

.clark-error-chart-panel .panel-heading {
  background-color: #555;
  padding: 5px;
  text-align: center;
  color: #bbb;
}

.clark-error-chart-panel-experiment {
  text-align: center;
  margin-top: 10px;
  margin-bottom: -10px;
}
