
input.excel {
    border-width: 0;
    box-shadow: inset 0 0 0 0 black;
    transition: .1s;
    outline: none;
}

input.excel:focus {
    border: none;
    margin: -1px;
    box-shadow: cornflowerblue 0 0 0.5pt 1pt, inset 0 0 0 2pt cornflowerblue;
    transition: .1s;
    outline: none;
    z-index: 1;
}
